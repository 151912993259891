export const environment = {
  production: true,
  name: 'preprod',
  baseUrl: 'https://preprod.vacaymyway.com',
  auth0: {
    domain: 'vacay-preprod.us.auth0.com',
    clientId: '0rZkY9VwJAtrRyN8wnlx01jEJu68zfH2',
    responseType: 'token',
    audience: 'https://api.vacaymyway.net/',
    requestedScopes: 'openid email profile offline_access',
    database: 'Username-Password-Authentication',
  },
  unleash: {
    clientKey: '91b769c7-8307-43af-bf7d-64b3b79617f1',
    url: '/proxy',
    unleashEnabled: true,
  },
  mapbox: {
    accessToken:
      'pk.eyJ1IjoidmFjYXlteXdheSIsImEiOiJja2V2ZTF1bjcwY205MnRxb2JqaGQ4MHgyIn0.3LtqHk1pGG8eBOf525X3Rg',
  },
  content: {
    url: 'https://content.preprod.vacaymyway.com/',
  },
  wordpress: {
    baseUrl: 'https://blog-preprod.vacaymyway.com/',
  },
  stripe: {
    publishableKey:
      'pk_test_51HCVXyGBLBiSxSLhd6fdqOk0um4EolbMa4V2ONzfjDUCFqWQSN4e5aaRJkNv6apQMxqcOcpLxNXMTYaETMHvAJmX00KZFeZ8D1',
    mock: false,
  },
  ga: {
    enabled: true,
    gtmId: 'GTM-MLJJ373',
    gaId: 'UA-180936694-2',
  },
  ga4: {
    ga4Id: 'G-NFEBF2DEGD',
    awConversionId: 'AW-467079275',
  },
  sentry: {
    enabled: true,
    logError: false,
  },
  superhog: {
    incidentFormUrl:
      '//forms.zohopublic.eu/guardhog/form/SUPERHOGPMCclaimform/formperma/vmlmCh2VxQgMjNqbn2ncwp3tREsEK5gyiY6BC8Q8I3s',
  },
  impact: {
    campaignId: 13766,
    eventTypeId: 25494,
  },
  smartlook: {
    initKey: '101b8a73c80f5453b5ae0e6365ec994e6e693c2f',
  },
  ipApi: {
    key: '6tCcKdVLmbXwytY',
  },
  propertyServiceUrl: {
    url: 'http://vacay-property.vmw-preprod.svc.cluster.local:3000',
  },
  noiseaware: {
    promoCode: 'VMW33',
  },
  metaPixel: {
    id: '375678944150786',
  },
  integrations: {
    hostfully: {
      clientId: 'U7qhfxwTUCS95MPRYDFqbIBqaJLvgOSE',
      auth0Url: 'https://sandbox.hostfully.com/api/auth/oauth/authorize',
      redirectUri: 'https://preprod.vacaymyway.com/integration/hostfully',
    },
  },
  enabledFeatures: {
    chatContextDetail: false,
    devPages: false,
    propertyBooking: true,
    reviews: false,
    invoice: false,
    bookingChangeDates: false,
    reportingUser: true,
    integration: true,
    hostfully: true,
    nextpax: false,
    hostaway: false,
    streamline: false,
    boost: false,
    smartlook: false,
    impact: false,
    chat: true,
    ga4: true,
    ga4ConversionReport: false,
    accountWithdrawal: true,
    noiseaware: false,
    christmasTheme: false,
    snowflakes: false,
    searchExactPrices: false,
    propertyTaxes2: false,
    pricingV2: false,
    pricingV2updates: false,
    instantApprove: false,
    weeklyAndMonthlyDiscount: false,
    rewardPointDiscount: false,
    rewardPointEarning: false,
    metaPixel: false,
    propertySearchV2: false,
    expressCheckout: false,
    smartlookRecordAll: false,
    signUpRewardPoints: {
      enabled: true,
      value: 10000,
    },
    reviewSubmittedRewardPoints: {
      enabled: true,
      value: {
        baseAmount: 500,
        highlightAmount: 250,
        textAmount: 1000,
      },
    },
    signUpRewardPointsTopBanner: false,
    review: true,
  },
};
